

var untils = {
    countryCodeArry:[
        "Angola-安哥拉-244",
        "Afghanistan-阿富汗-93",
        "Albania-阿尔巴尼亚-355",
        "Algeria-阿尔及利亚-213",
        "Andorra-安道尔共和国-376",
        "Anguilla-安圭拉岛-1264",
        "Antigua and Barbuda-安提瓜和巴布达-1268",
        "Argentina-阿根廷-54",
        "Armenia-亚美尼亚-374",
        "Ascension-阿森松-247",
        "Australia-澳大利亚-61",
        "Austria-奥地利-43",
        "Azerbaijan-阿塞拜疆-994",
        "Bahamas-巴哈马-1242",
        "Bahrain-巴林-973",
        "Bangladesh-孟加拉国-880",
        "Barbados-巴巴多斯-1246",
        "Belarus-白俄罗斯-375",
        "Belgium-比利时-32",
        "Belize-伯利兹-501",
        "Benin-贝宁-229",
        "Bermuda Is-百慕大群岛-1441",
        "Bolivia-玻利维亚-591",
        "Botswana-博茨瓦纳-267",
        "Bosnia and Herzegovina-波斯尼亚和黑塞哥维那-387",
        "Brazil-巴西-55",
        "Brunei-文莱-673",
        "Bulgaria-保加利亚-359",
        "Burkina Faso-布基纳法索-226",
        "Burma-缅甸-95",
        "Burundi-布隆迪-257",
        "Cameroon-喀麦隆-237",
        "Canada-加拿大-1",
        "Cayman Is-开曼群岛-1345",
        "Central African Republic-中非共和国-236",
        "Chad-乍得-235",
        "Chile-智利-56",
        "China-中国-86",
        "Colombia-哥伦比亚-57",
        "Croatia-克罗地亚-385",
        "Cook Is-库克群岛-682",
        "Costa Rica-哥斯达黎加-506",
        "Cuba-古巴-53",
        "Cyprus-塞浦路斯-357",
        "Czech Republic-捷克-420",
        "Denmark-丹麦-45",
        "Democratic Republic of the Congo-刚果(金)-243",
        "Djibouti-吉布提-253",
        "Dominica Rep-多米尼加共和国-1809",
        "Dominica Rep-多米尼加共和国-1829",
        "Dominica Rep-多米尼加共和国-1849",
        "Ecuador-厄瓜多尔-593",
        "Egypt-埃及-20",
        "EI Salvador-萨尔瓦多-503",
        "Estonia-爱沙尼亚-372",
        "Ethiopia-埃塞俄比亚-251",
        "Fiji-斐济-679",
        "Finland-芬兰-358",
        "France-法国-33",
        "French Guiana-法属圭亚那-594",
        "French Polynesia-法属玻利尼西亚-689",
        "Gabon-加蓬-241",
        "Gambia-冈比亚-220",
        "Georgia-格鲁吉亚-995",
        "Germany-德国-49",
        "Ghana-加纳-233",
        "Gibraltar-直布罗陀-350",
        "Greece-希腊-30",
        "Grenada-格林纳达-1473",
        "Guam-关岛-1671",
        "Guatemala-危地马拉-502",
        "Guinea-几内亚-224",
        "Guyana-圭亚那-592",
        "Haiti-海地-509",
        "Honduras-洪都拉斯-504",
        "Hongkong,China-中国香港-852",
        "Hungary-匈牙利-36",
        "Iceland-冰岛-354",
        "India-印度-91",
        "Indonesia-印度尼西亚-62",
        "Iran-伊朗-98",
        "Iraq-伊拉克-964",
        "Ireland-爱尔兰-353",
        "Israel-以色列-972",
        "Italy-意大利-39",
        "Ivory Coast-科特迪瓦-225",
        "Jamaica-牙买加-1876",
        "Japan-日本-81",
        "Jordan-约旦-962",
        "Kampuchea (Cambodia )-柬埔寨-855",
        "Kazakstan-哈萨克斯坦-7",
        "Kenya-肯尼亚-254",
        "Korea-韩国-82",
        "Kuwait-科威特-965",
        "Kyrgyzstan-吉尔吉斯斯坦-996",
        "Laos-老挝-856",
        "Latvia-拉脱维亚-371",
        "Lebanon-黎巴嫩-961",
        "Lesotho-莱索托-266",
        "Liberia-利比里亚-231",
        "Libya-利比亚-218",
        "Liechtenstein-列支敦士登--423",
        "Lithuania-立陶宛-370",
        "Luxembourg-卢森堡-352",
        "Macao,china-中国澳门-853",
        "Madagascar-马达加斯加-261",
        "Malawi-马拉维-265",
        "Malaysia-马来西亚-60",
        "Maldives-马尔代夫-960",
        "Mali-马里-223",
        "Malta-马耳他-356",
        "Mariana Is-马里亚那群岛-1670",
        "Martinique-马提尼克-596",
        "Mauritius-毛里求斯-230",
        "Mexico-墨西哥-52",
        "Moldova-摩尔多瓦-373",
        "Monaco-摩纳哥-377",
        "Mongolia-蒙古-976","Montenegro-黑山共和国-382",
        "Montserrat Is-蒙特塞拉特岛-1664",
        "Morocco-摩洛哥-212",
        "Mozambique-莫桑比克-258",
        "Namibia-纳米比亚-264",
        "Nauru-瑙鲁-674",
        "Nepal-尼泊尔-977",
        "Netheriands Antilles-荷属安的列斯群岛-599",
        "Netherlands-荷兰-31",
        "New Zealand-新西兰-64",
        "Nicaragua-尼加拉瓜-505",
        "Niger-尼日尔-227",
        "Nigeria-尼日利亚-234",
        "North Macedonia-北马其顿-389",
        "North Korea-朝鲜-850",
        "Norway-挪威-47",
        "Oman-阿曼-968",
        "Pakistan-巴基斯坦-92",
        "Panama-巴拿马-507",
        "Papua New Cuinea-巴布亚新几内亚-675",
        "Paraguay-巴拉圭-595",
        "Peru-秘鲁-51",
        "Philippines-菲律宾-63",
        "Poland-波兰-48",
        "Portugal-葡萄牙-351",
        "Puerto Rico-波多黎各-1787",
        "Qatar-卡塔尔-974",
        "Reunion-留尼旺-262",
        "Romania-罗马尼亚-40",
        "Russia-俄罗斯-7",
        "Republic of the Congo-刚果(布)-242",
        "Saint Lueia-圣卢西亚-1758",
        "Saint Vincent-圣文森特岛-1784",
        "Samoa Eastern-东萨摩亚(美)-684",
        "Samoa Western-西萨摩亚-685",
        "San Marino-圣马力诺-378",
        "Sao Tome and Principe-圣多美和普林西比-239",
        "Saudi Arabia-沙特阿拉伯-966",
        "Senegal-塞内加尔-221",
        "Seychelles-塞舌尔-248","Serbia-塞尔维亚-381",
        "Sierra Leone-塞拉利昂-232",
        "Singapore-新加坡-65",
        "Slovakia-斯洛伐克-421",
        "Slovenia-斯洛文尼亚-386",
        "Solomon Is-所罗门群岛-677",
        "Somali-索马里-252",
        "South Africa-南非-27",
        "Spain-西班牙-34",
        "SriLanka-斯里兰卡-94",
        "St.Lucia-圣卢西亚-1758",
        "St.Vincent-圣文森特-1784",
        "Sudan-苏丹-249",
        "Suriname-苏里南-597",
        "Swaziland-斯威士兰-268",
        "Sweden-瑞典-46",
        "Switzerland-瑞士-41",
        "Syria-叙利亚-963",
        "Taiwan,China-中国台湾-886",
        "Tajikstan-塔吉克斯坦-992",
        "Tanzania-坦桑尼亚-255",
        "Thailand-泰国-66",
        "Togo-多哥-228",
        "Tonga-汤加-676",
        "Trinidad and Tobago-特立尼达和多巴哥-1868",
        "Tunisia-突尼斯-216",
        "Turkey-土耳其-90",
        "Turkmenistan-土库曼斯坦-993",
        "Uganda-乌干达-256",
        "Ukraine-乌克兰-380",
        "United Arab Emirates-阿拉伯联合酋长国-971",
        "United Kingdom-英国-44",
        "United States of America-美国-1",
        "Uruguay-乌拉圭-598",
        "Uzbekistan-乌兹别克斯坦-998",
        "Venezuela-委内瑞拉-58",
        "Vietnam-越南-84",
        "Yemen-也门-967",
        "Zimbabwe-津巴布韦-263",
        "Zaire-扎伊尔-243",
        "Zambia-赞比亚-260"
    ],

    GetQueryString:function(name){
        // 获取参数
        var url = window.location.search;
        // 正则筛选地址栏
        var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        // 匹配目标参数
        var result = url.substr(1).match(reg);
        //返回参数值
        return result ? decodeURIComponent(result[2]) : null;
    },
    getUrlFileType:function (url) {
        //通过URL后缀取文件的路径
        var  propty = '';
        //PPT
        if (url.indexOf('.ppt') > -1 || url.indexOf('.pptx') > -1 ){
            propty = 'ppt'
        }
        if (url.indexOf('.mp3') > -1  ){
            propty =  'mp3'
        }
        if (url.indexOf('.mp4') > -1  ){
            propty =  'mp4'
        }
        if (url.indexOf('.png') > -1  ||  url.indexOf('.jpg') > -1){
            propty =  'jpg'
        }
        return propty
    },
    loadStyles:function(url) {
        var link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = url;
        document.getElementsByTagName("head")[0].appendChild(link);
    },
    loadScript:function(url,callback) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        document.body.appendChild(script)
        if(callback){
            callback();
        }
    },
    week:function(getDay){
        var week="";
        switch(getDay){
            case 0:
                week="Sunday";
                break;
            case 1:
                week="Monday";
                break;
            case 2:
                week="Tuesday";
                break;
            case 3:
                week="Wednesday";
                break;
            case 4:
                week="Thursday";
                break;
            case 5:
                week="Friday";
                break;
            case 6:
                week="Saturday";
                break;
            case 7:
            	week="Sunday";
            break;
        }
        return week;
    },

    timeHtml:function(time,str,ifConversion){
        // if(type==="universal"){
        //     //得到标准时区的时间的函数
        //     var d = new Date(time);
        //     //得到1970年一月一日到现在的秒数
        //     var len = d.getTime();
        //     //本地时间与GMT时间的时间偏移差
        //     var offset = d.getTimezoneOffset() * 60000;
        //     //得到现在的格林尼治时间
        //     time = len + offset+ 3600000 * i;
        // }
        //统一改为北京时间（东八区）
        if(!ifConversion){
            time=new Date(time).getTime()+new Date(time).getTimezoneOffset() * 60000+3600000 * 8;
        }
        var date = new Date(time);
		Y = date.getFullYear() + '-';
		M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
		_M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/';
		mM = date.getMonth()+1 ;
		D = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
		h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
		m = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
		s =date.getSeconds() < 10 ? ":0"+date.getSeconds():":"+date.getSeconds();

        if(str === 'beijin-cn'){
            return ('Beijing Time ' + h + m + s  +' , ' + D + ' - ' + ((date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1))  + ' / ' + untils.week(date.getDay()))
        }else if (str == "YY-MM-DD") {
            return (Y + M + D);
        }else if (str == "NN-YY") {
            return (date.getFullYear()+"-"+(date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1));
		}else if(str=="YY-MM-DD-HH-MM-SS"){
			return (Y+M+D+' '+h+m+s);
		}else if(str=="YY-MM-DD-HH-MM"){
			return (Y+M+D+' '+h+m);
		}else if(str=="MM-DD"){
			return (M+D);
		}else if(str=="MM/DD"){
			return (_M+D);
		}else if(str=="hh-mm"){
			return (h+m);
		}else if(str=="YY-MM-DD-Week"){
			return (Y+M+D+' '+untils.week(date.getDay()));
		}else if (str == "YY-MM-DD-Week-hh-mm") {
            return (Y + M + D + ' ' + untils.week(date.getDay())) + ' ' + h + m;
        }else if (str == "YY-MM-DD-hh-mm-Week") {
            return (Y + M + D +' '+ h + m +' '+ untils.week(date.getDay()));
        }else if (str == "Week") {
            return (untils.week(date.getDay()));
        }else if(str=="hh"){
			return (h);
		}else if(str=="MM-DD-HH-MM-CN"){
			return (M.substring(0,M.length-1)+"-"+D+' '+h+m);
		}else if(str=="MM-DD-CN"){
			return (M.substring(0,M.length-1)+"-"+D);
		}else if(str=="YY-MM-DD-CN"){
			return Y.substring(0,Y.length-1)+"-"+(M.substring(0,M.length-1)+"-"+D);
		}else if(str=="mm"){
			return (mM);
		}else if (str == "hh-mm-ss") {
            return (h+m+s);
        }
    },
    countryCodeChange:function () {
        var form = layui.form();
        form.on('select(countryCode)', function(data){

            $(".cuntry-code .layui-unselect").val(data.value);
        });
    },
    countryCode: function(countryCodeList,countryCode){
        var countryCodeArry = this.countryCodeArry
        //国家码js转换
        var strHtml="";
        for(var i=0;i<countryCodeArry.length;i++){
            var code = countryCodeArry[i].replace(/[^0-9]/ig,"");
            var isSelect = false;
            if(countryCode === undefined){
                if(code === "86"){
                    isSelect = true;
                }
            }else{
                if(code === countryCode){
                    isSelect = true;
                }
            }
            if(isSelect){
                strHtml+='<option selected value="'+code+'">'+countryCodeArry[i]+'</option>';
            }else{
                strHtml+='<option value="'+code+'">'+countryCodeArry[i]+'</option>';
            }

        }
        $(countryCodeList).html(strHtml);
        var layer = layui.layer
            ,form = layui.form();

        form.render('select');
        if(countryCode === undefined){
            $(countryCodeList).parent().find('.layui-unselect').val("86");
        }else{
            $(countryCodeList).parent().find('.layui-unselect').val(countryCode);
        }

        form.on('select(countryCode)', function(data) {
            $(countryCodeList).parent().find('.layui-unselect').val(data.value);
            //form.render('select');
        });
    },
    country: function(countryCodeList, countryCode) {
        var countryCodeArry = this.countryCodeArry

        //国家码js转换
        var strHtml = "";
        for (var i = 0; i < countryCodeArry.length; i++) {
            var country = countryCodeArry[i].split('-');
            var isSelect = false;
            if (countryCode === country[0]) {
                isSelect = true;
            } else {
                isSelect = false;
            }
            if (isSelect) {
                strHtml += '<option selected value="' + country[0] + '">' + country[0] + '</option>';
            } else {
                if(i==0){strHtml += '<option value="">Choose Nationality</option>'}
                strHtml += '<option value="' +  country[0] + '">' +  country[0] + '</option>';
            }

        }

        $(countryCodeList).html(strHtml);
        var layer = layui.layer,
            form = layui.form();
        form.render('select');
    },
    templateString:function () {
       if(template){
           template.defaults.imports.timeHtml = function (time, str) {
               return timeHtml(time, str);
           };
           template.defaults.imports.str = function (str) {
               return parseInt(str);
           };
           template.defaults.imports.courseTitle=function(val1,val2){
               var str="Lesson "+val1;
               if(str==val2){
                   return false;
               }else{
                   return true;
               }
           };
           template.defaults.imports.materialsStr=function(arr){
               var strArr="";
               for(var i=0;i<arr.length;i++){
                   if(i==0){
                       strArr=arr[i].materialId;
                   }else{
                       strArr+=","+arr[i].materialId;
                   }
               }
               return strArr;
           };
           template.defaults.imports.strWeek=function(str)
           {
               var arr = ["一","二","三","四","五","六","七","八","九"];
               return arr[str];
           };
           template.defaults.imports.ToF2=function(val){
               return parseFloat(val).toFixed(2);
           };
           template.defaults.imports.mathStrings=function(str){
               return Math.abs(parseFloat(str)).toFixed(2);
           };
           template.defaults.imports.subString1 = function (str) {
               str = str.substring(1)
               return str
           };
           template.defaults.imports.bankName = function (strs) {
               return strs = strs.substring(0, 1) + '******'.substring(0, strs.length - 1)
           };
           template.defaults.imports.bankId = function (strs) {
               return strs = '**** **** **** ' + strs.substring(strs.length - 4, strs.length)
           };
           template.defaults.imports.phoneId = function (strs) {
               return strs = strs.substring(0, 3) + '******************'.substring(0, 4) + strs.substring(strs.length - 4, strs.length)
           };
           template.defaults.imports.strRelpace4str = function (str) {
               return str.substring(str.length - 4, str.length)
           };
           template.defaults.imports.PFloat = function (str) {
               return parseFloat(str).toFixed(2)
           };

           template.defaults.imports.courseTitle = function (val1, val2) {
               var str = "Lesson " + val1;
               if (str == val2) {
                   return false;
               } else {
                   return true;
               }
           };
           template.defaults.imports.materialsStr = function (arr) {
               var strArr = "";
               for (var i = 0; i < arr.length; i++) {
                   if (i == 0) {
                       strArr = arr[i].materialId;
                   } else {
                       strArr += "," + arr[i].materialId;
                   }
               }
               return strArr;
           };
           template.defaults.imports.stringsF2 = function (str) {
               return Math.abs(parseFloat(str)).toFixed(2);
           };
           template.defaults.imports.stringsName = function (str) {
               return str.substr(0, 2) + "**" + str.substr(str.length - 2, str.length)
           };
           template.defaults.imports.stringsNub = function (str) {
               return str.substr(0, 7) + "***********" + str.substr(str.length - 1, str.length)
           };
           template.defaults.imports.PFloat=function(str){
               return parseFloat(str).toFixed(2)
           };
           template.defaults.imports.audioTime=function(times,curr) {
               curr = curr || 0;
               times= times - curr
               var count = times%60>10? times%60:'0'+times%60;
               return '0'+Math.floor(times/60)+':'+count
           }
       }
    },
    getExplorerInfo: function() {
    var explorer = window.navigator.userAgent.toLowerCase();

    //ie
    if (explorer.indexOf("msie") >= 0) {
        var ver = explorer.match(/msie ([\d.]+)/)[1];
        return { type: "IE", version: ver };
    }
    //firefox
    else if (explorer.indexOf("firefox") >= 0) {
        var ver = explorer.match(/firefox\/([\d.]+)/)[1];
        return { type: "Firefox", version: ver };
    }
    //Chrome
    else if (explorer.indexOf("chrome") >= 0) {
        var ver = explorer.match(/chrome\/([\d.]+)/)[1];
        return { type: "Chrome", version: ver };
    }
    //Opera
    else if (explorer.indexOf("opera") >= 0) {
        var ver = explorer.match(/opera.([\d.]+)/)[1];
        return { type: "Opera", version: ver };
    }
    //Safari
    else if (explorer.indexOf("Safari") >= 0 || explorer.indexOf("safari") >= 0) {
        var ver = explorer.match(/version\/([\d.]+)/)[1];
        return { type: "Safari", version: ver };
    }
},
    addTime:function () {
        $('.head-nav>.web-width').append('<p class="fr web-times" >'+ untils.timeHtml(new Date().getTime(),'beijin-cn')+'</p>')

        setInterval(function () {
            if($('.web-width .web-times').length){
                $('.web-width .web-times').text(untils.timeHtml(new Date().getTime(),'beijin-cn'))
            }else {
                $('.head-nav>.web-width').append('<p class="fr web-times" >'+ untils.timeHtml(new Date().getTime(),'beijin-cn')+'</p>')
            }
        },1000)
    },
    language:'en-US'

};
var toast = {
    success:function (title,callback) {
        var layer = layui.layer;
        layer.msg(title,{icon:1,time:1200},function () {
            if(callback){
                callback();
            }
        })
    },
    error:function (title,callback) {
        var layer = layui.layer;
        layer.msg(title,{icon:2,time:1200},function () {
            if(callback){
                callback();
            }
        })
    },
    warming:function (title,callback,time) {
        var layer = layui.layer;
        layer.msg(title,{icon:3,time:time !== undefined ? time : 1200},function () {
            if(callback){
                callback();
            }
        })
    },
    showLoading:function () {
        if($('.iou-loading').length>0){
            $('.iou-loading').removeClass('fadeOut')
        }else{
            $('body').append('<div style="overflow: hidden"><div class="iou-loading animated"><div class="loading-bg"></div><div class="refreshBox ball-clip-rotate"><div></div></div><div class="loading-text">Processing, please wait...</div></div></div>');
        }
        if($('.delay-box').length > 0){
            $(".delay-box").show();
        }else{
            $(".head").prepend('<div class="delay-box">\n' +
                '\t<div class="db-bg"></div>\n' +
                '\t<div class="bar-top"><i></i></div>\n' +
                '\t<div class="page-width">\n' +
                '\t<p class="fl">Failed to connect to our main server. Please click "Switch" to try the suggested option.</p>\n' +
                '\t<div class="fr down-box">\n' +
                '\t\t<div class="db-select">\n' +
                '\t\t\t<h2><span class="dsc-01">hk.icoachu.cn</span><i></i></h2>\n' +
                '\t\t\t<ul>\n' +
                '\t\t\t\t<li class="active"><span class="dsc-01">hk.icoachu.cn</span><i></i></li>\n' +
                '\t\t\t\t<li><span class="dsc-02">us.icoachu.cn</span><i></i></li>\n' +
                '\t\t\t\t<li><span class="dsc-03">uk.icoachu.cn</span><i></i></li>\n' +
                '\t\t\t\t<li><span class="dsc-04">ure.icoachu.cn</span><i></i></li>\n' +
                '\t\t\t</ul>\n' +
                '\t\t</div>\n' +
                '\t\t<span class="switch-btn">Switch</span>\n' +
                '\t\t<i class="colse-box"></i>\n' +
                '\t</div>\n' +
                '</div></div>');
        }
    },
    hideLoading:function () {
        if($('.iou-loading').length>0){
            $('.iou-loading').addClass('fadeOut');
        }
        if($('.delay-box').length > 0){
            $(".delay-box").hide();
        }
    }
}

Number.prototype.bytesToSize = function () {
    var bytes = this;
    if (bytes === 0) return '0 B';
    var k = 1024;
    var sizes = ['B','KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
};

function isEqual(obj1,obj2){
    for(var name in obj1){
        if(obj1[name]!==obj2[name]) return false;
    }
    for(var name in obj2){
        if(obj1[name]!==obj2[name]) return false;
    }
    return true;
}
//endpoint:'oss.icoachu.cn',
var uploadInit = function(callback){
    toast.showLoading()
    var client = "";
    if (callback){
        callback(client);
    }
    setTimeout(function () {
        toast.hideLoading()
    },1000)
}

var uploadFlog = function (callback) {
    var browserVersion = untils.getExplorerInfo();
    if ( (browserVersion.type === "Safari" && parseInt(browserVersion.version) < 6) ||  (browserVersion.type === "IE" && parseInt(browserVersion.version) < 10) ){
        toast.warming('You need to upgrade your browser or Safari to access certain functionalities of I Coach U.',null,2100)
        return false;
    }
    if (callback) callback();
}
// //国际化
// var guojihua = function(){
//     if($('.head .top-left').length>0){
//         $('.head .top-left').append('<a style="cursor:pointer;position:relative;top:12px;" class="J-en">中文</a>');
//         $('.J-en').on('click',function(e){
//             e.preventDefault();
//             var local = window.location.href;
//             var eLocal = local.replace('/en','/cn');
//             window.location.href=eLocal;
//         })
//     }
// }

$(function () {
    untils.templateString();
    // untils.addTime()
    // guojihua()
})

String.prototype.gblen = function() {
    var len = 0;
    for (var i=0; i<this.length; i++) {
        if (this.charCodeAt(i)>127 || this.charCodeAt(i)==94) {
            len += 2;
        } else {
            len ++;
        }
    }
    return len;
}
